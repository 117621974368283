import React from 'react';
import Nav from 'react-bootstrap/Nav';
export default function baseNav(){
    return(
        <>
        <Nav defaultActiveKey="/home" className="flex-column">
        <Nav.Item>Encuentranos en:</Nav.Item>
        <Nav.Link href="https://api.whatsapp.com/send?phone=+573012452679" className="text-success">
            <i className="bi bi-whatsapp md"></i>
            &nbsp;Whatsapp
            </Nav.Link>
        <Nav.Link href="https://www.facebook.com/Tecnitec-103387205467191">
            <i className="bi bi-facebook md"></i>
            &nbsp;Facebook
            </Nav.Link>
        <Nav.Link eventKey="disabled" disabled className="text-warning">
        tecnitec10@gmail.com&nbsp;<i className="bi bi-envelope md"></i>
        </Nav.Link>
        </Nav>
        </>
    )
}