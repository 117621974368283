import React from 'react';
export default function banner(){
    return(
    <div className="bg-gray">
        <div className="spacer-mid"></div>
        <div className="container text-center" >
                <p id="banner" className="display-4">Garant&iacute;a De <em className="circle">12</em> Meses</p>
                <p className="display-6">Domicilios En Toda El &Aacute;rea Metropolitana</p>
        </div>
        <div className="spacer-mid"></div>
    </div>
    )
  }