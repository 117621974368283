import React from 'react'
import Image from 'react-bootstrap/Image';
import whatsapp from './logos/mensaje.png'
export default function wpp()
{
    return(
    
            <div className="fixed-contact-left">
            <a href="https://api.whatsapp.com/send?phone=+573012452679">
            <Image src={whatsapp}  width="72px" height="72px" alt="whatsapp"/>
            </a>  
            </div>
      
    )
}