import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals'
//import Main from './js/load/Main'
/*
if ("serviceWorker" in navigator){
  navigator.serviceWorker.register("sw.js").then(registration=>{
    console.log("SW Registered!")
    console.log(registration)
  }).catch(error =>{
    console.log("SW Registration Failed!")
    console.log(error)
  })
}
*/
ReactDOM.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>,
  document.getElementById('root')
);
//reportWebVitals()
//Main();
