import React from 'react';
import Image from 'react-bootstrap/Image';
import Neveras from './img/pt0.png';
import Lavadoras from './img/pt1.png';
import Nevecones from './img/pt2.png';
export default function About() {

  return (
    <div>
      <Articulo />
    </div>
  );
}
var n = 0
var items = ["Neveras", "Lavadoras", "Nevecones"]
var images = [Neveras, Lavadoras, Nevecones]
class Articulo extends React.Component
{
  constructor(props)
  {
    super(props)
  
    this.state = {item: "", image:""}
  }
  
  componentDidMount()
  {
    this.timerID = setInterval(
      ()=>this.tick(),
      1000
      )
  }
  componentWillUnmount()
  {
    clearInterval(this.timerID)
  }
  
  tick()
  {
    n++
    if(n > 3)
    {
      n = 1
    }
    //console.log( )
    this.setState(
      {item: items[n-1],image:images[n-1]}
    )
  }
  render()
  {

    return(
          <div>
            <Image src={this.state.image} width="300px" height="400px"/>
            <h2 className="display-6">{this.state.item}</h2> 
          </div>     
    );
  }
}

