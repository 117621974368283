var i = 0

function typeText(){
    
    let type = '301 245 2679 - 604 322 9262'
    let speed = 50
    //console.log(type)
    if (i < type.length)
    {
        document.getElementById('phone').innerHTML += type.charAt(i)
        i++
        setTimeout(typeText, speed)
    }
}
export{typeText}