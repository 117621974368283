import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import whirlpool from './logos/whirlpool.svg';
import centrales from './logos/centrales.svg';
import general from './logos/GE-logo.png';
import lg from './logos/lg-logo.png';
import mabe from './logos/mabe.svg';
import samsung from './logos/Samsung-Logo.png';
import nevera from './logos/nevera.png';
import lavadora from './logos/lavadora.png';
import nevecon from './logos/nevecon.png';
import Banner from './Banner';
export default function Service() {
  return (
            <>
            <Banner/>
                <Container className="languages">
                    <h3 className="md text-center my-2 "> 
                        Mantenimiento y Reparaci&oacute;n De Todas Las Marcas.
                    </h3>
                        <Row>
                            <Col md={4} className="g-2">
                                <div className="text-center">
                                    <i className="bi bi-wrench xl text-warning"></i>
                                    <h3 className="display-6">Reparaci&oacute;n</h3>
                                </div>
                                Ofrecemos <b>Servicio T&eacute;cnico</b> especializado para tus electrodom&eacute;sticos, todos nuestros trabajos cuentan con garant&iacute;a.
                            </Col>
                            
                            <Col md={4} className="g-2">
                                <div className="text-center">
                                    <i className="bi bi-gear-fill xl text-warning"></i>
                                    <h3 className="display-6">Mantenimiento</h3>
                                    
                                </div>
                                Realizamos <b>Mantenimiento</b> preventivo y adecuado, para que tus electrodom&eacute;sticos sigan funcionando adecuadamente.
                            </Col>
                            <Col md={4} className="g-2">
                                <div className="text-center">
                                    <i className="bi bi-plug-fill xl text-warning"></i>
                                    <h3 className="display-6">Instalaci&oacute;n</h3>
                                </div>
                                Realizamos la <b>Instalaci&oacute;n</b>  responsable de tus electrodom&eacute;sticos, para que funcionen correctamente y sean duraderos.
                            </Col>
                        </Row>
                    <div className="flex">
                            <div className="logos">
                            <Image src={whirlpool} width="90" height="90" alt=""/>
                            </div>
                            <div className="logos">
                                <Image src={centrales} width="90" height="90" alt=""/>
                            </div>

                            <div className="logos">
                            <Image src={general}className="img-fluid" width="90" height="90" alt=""/>
                            </div>

                            <div className="logos">
                            <Image src={lg} width="90" height="90" alt=""/>
                            </div>

                            <div className="logos">
                            <Image src={mabe} width="90" height="90" alt=""/>
                            </div>
                            <div className="logos">
                            
                            <Image src={samsung} width="90" height="90" alt=""/>
                            </div>
                    </div>
                </Container>
                <Container>
                    <Row>
                        <Col md={4} className="g-2 text-center">
                            <div>
                                <img src={nevera} className="img-fluid w-25 d-none d-md-block mx-auto" alt=""/>
                            </div>
                        </Col>
                        <Col md={4} className="g-2">
                            <div>
                                <img src={lavadora} className="img-fluid w-25 d-none d-md-block mx-auto" alt=""/>  
                            </div>
                        </Col>
                        <Col md={4} className="g-2">
                            <div>
                                <img src={nevecon} className="img-fluid w-25 d-none d-md-block mx-auto" alt=""/>  
                            </div>
                        </Col>
                    </Row>
                    <Row className="text-center">
                        <Col md={4} className="g-2">
                            <h3 className="display-6">Neveras</h3>
                        </Col>
                        <Col md={4} className="g-2">
                            <h3 className="display-6">Lavadoras</h3>
                        </Col>
                        <Col md={4} className="g-2">
                            <h3 className="display-6">Nevecones</h3> 
                        </Col>
                    </Row>
                </Container>
            </>
  );
}