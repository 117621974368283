import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import tecnico from './transitions/tecnico.png';
import servicio from './transitions/servicio.png';
import especializado from './transitions/especializado.png';

function Contact() {
    return (<>
            <Container className="my-2 py-2">
              <Row><h4 className="text-center text-dark display-4">Quienes somos</h4>
                    <Col md={2}></Col>
                    <Col md={8} className="py-2">
                          <Carousel className="bg-light">
                          <Carousel.Item interval={2000}>
                            <Image
                            className="d-block w-100"
                            src={servicio}
                            alt="First slide"
                            />
                          </Carousel.Item>
                          <Carousel.Item interval={1000}>
                            <Image
                            className="d-block w-100"
                            src={tecnico}
                            alt="Second slide"
                            />
                          </Carousel.Item>
                          <Carousel.Item>
                            <Image
                            className="d-block w-100"
                            src={especializado}
                            alt="Third slide"
                            />
                          </Carousel.Item>
                          </Carousel>
                    </Col>
                    <Col md={2}></Col>
              </Row>
            </Container>
            </>
    );
  }
  
export default Contact;
