
import { Component } from 'react';
import './css/App.css';
import 'bootstrap/dist/css/bootstrap.css';
//GOOGLE ANALYTICS FUNCTIONALITY
import ReactGA from 'react-ga';
//import logo from './logo.svg';
import Navbar from './components/Navbar';
import Main from './js/load/Main'
import About from './components/About';
import Contact from './components/Contact';
import Home from './components/Home';
import Service from './components/Service';
import Whatsapp from './components/Whatsapp';
import Footer from './components/Footer';
//import Cookies from './components/Cookieconsent';
import {HashRouter as Router, Switch, Route} from 'react-router-dom';
class App extends Component {
  setGA = () =>{
    ReactGA.initialize('UA-209126814-1');
    ReactGA.pageview('Init page view')
  }
  
  componentDidMount(){
    this.setGA();
  }
  render(){
    return (
    <div className="App">
      <Router>
        <Navbar/>
        <Switch>
          <Route path='/' exact component={Home}/>
          <Route path='/servicio' exact component={Service} />
          <Route path='/contacto' exact component={Contact}/>
          <Route path='/nosotros' exact component={About}/>
        </Switch>
      </Router>
      <Whatsapp />
      <Footer />
    </div> 
  );
  }
  
}
Main()
export default App;
