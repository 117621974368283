
import React from 'react';
import logo from './img/logo48.png';
import {Link} from 'react-router-dom';
import {Nav, Navbar, Container} from 'react-bootstrap';
function Navigate() {
    const bg = {
      background:"#f4f4f4",
      color:"#1a66ff"
    }
  return (
        <Navbar className="g-nav index999" expand="lg" sticky="top">
          <Container>
              <Navbar.Brand>
                <Link to=""> 
                <img src={logo} alt="Tecnitec"/>&nbsp;Inicio
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarScroll" style={bg} />
              <Navbar.Collapse id="navbarScroll">
              <Nav className="ms-auto right">
                <Link to="servicio">Servicio</Link>
                <Link to="contacto">Cont&aacute;ctenos</Link>
                <Link to="nosotros">Quienes somos</Link>   
              </Nav>
              </Navbar.Collapse>
          </Container>
        </Navbar>
  );
}
export default Navigate;