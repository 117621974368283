import React from 'react';
import Image from 'react-bootstrap/Image';
import Centro from './logos/tecnitec-logo.png';
import Especializado from './logos/logo96.png';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from './Nav'
export default function footer(){
    return(
    
<footer className="bg-dark text-light">
    <div className="pt-2 text-center">
    <Image src={Centro} className="md text-center" alt=""/>  
    </div>
    <div className="spacer"></div>
    <Container>
    <Row className="align-items-center">
        <Row className="text-center">
            <Col><h4 className="d-none d-md-block  text-warning">Tecnitec</h4></Col>
            <Col md={6}><h4 className="d-none d-md-block  text-warning">Domicilios</h4></Col>
            <Col><h4 className="d-none d-md-block  text-warning">Redes</h4></Col>
        </Row>
    </Row>
    <Row className="align-items-center">
        <Col className="text-center">
            <div className="spacer-mid"></div>
            <Image src={Especializado}  alt=""/>
            <div className="spacer-mid"></div>
            
            <p><em>
                T&eacute;cnicos especializados en reparaci&oacute;n y mantenimiento de tus electrodom&eacute;sticos
            </em>   
            </p>
        </Col>
        <Col md={6}>
            <Row>
                <div className="bg-dark text-light  text-center">
                    <div className="card-header text-danger"><h2><i className="bi bi-phone lg"></i>L&iacute;neas</h2></div>
                    <div className="card-body">
                        <ul>
                            <li><h6 className="md">301 245 2679</h6></li>
                            <li><h6 className="md">604 322 9262</h6></li>
                        </ul>
                    </div>
                    <div className="card-footer text-warning">Medell&iacute;n |&nbsp;Valle De Aburr&aacute; |&nbsp;Oriente</div>
                </div>
            </Row>
        </Col>
        <Col>
            <Nav />
        </Col>
    </Row>
    <Row>
        <Col>
        <div className="card-footer text-muted">|&#169;Tecnitec - 2021|</div>
        </Col>
    </Row>
    </Container>
    <div className="spacer-mid"></div>
</footer>  
      
    )
}