import {React, useState} from 'react';
import {Container, Button} from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import service from './img/service.png';
import tecnico from './img/tecnitec.png';
import Modal from 'react-bootstrap/Modal';

//import Modal from 'react-bootstrap/Modal';
function Contact() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const height={height:"40vh"};
    return (
      <>
      <Container className="box-shadow my-2 py-2" style={height}>
        <div className="spacer">
        <Row className="align-items-center">
            <Col md>
              <Image src={tecnico} className="mx-auto w-50 d-none d-md-block text-end" alt="servicio"/>
            </Col>
            <Col md>
            <h2 className="text-danger">&Aacute;rea De Cobertura</h2>
              <p>Haz click en el enlace</p>  
              <Button variant="danger" onClick={handleShow}>Domicilios</Button>
            </Col>
            <Col md >
              <Image src={service} className="mx-auto w-50 d-none d-md-block" alt="domicilio"/>
            </Col>
        </Row>
        </div>
       
     

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="bi bi-phone-vibrate lg"></i>&nbsp;
            
          </Modal.Title>
          <h5 className="modal-title text-primary text-center lg" id="exampleModalLabel">Domicilios</h5>
        </Modal.Header>
        <Modal.Body>
          
        <div id="profile" 
                    className="container-fluid bg-light g-4">
                        <h3 className="text-center fw-bold">
                        <i className="bi bi-geo-alt-fill text-danger lg"></i>
                          &Aacute;rea Metropolitana</h3> 
                        <div className="row justify-content-between text-dark">
                          <div className="col-lg-12">
                           
                            <ul className="list-group list-group-flush lead">
                                <div className="list-group-item">
                                  <span className="fw-bold text-center">Medell&iacute;n</span>
                                </div>
                                <div className="list-group-item">
                                  <span className="fw-bold">Sur:</span> Itagui, Envigado, Sabaneta, La Estrella y Caldas.
                                </div>
                                <div className="list-group-item">
                                  <span className="fw-bold">Norte:</span> Bello, Copacabana, Girardota y Barbosa.
                                </div>
                                <div className="list-group-item">
                                  <span className="fw-bold">Oriente:</span> El Retiro, La Ceja, Rionegro y Guarne.
                                </div>
                              </ul>
                          </div>
                        </div>
                    </div>
        </Modal.Body>
        <Modal.Footer>
          <h4 className="fw-bold">Visitanos en Facebook:</h4>
          <a href="https://www.facebook.com/Tecnitec-103387205467191">
                <i className="bi bi-facebook lg"></i>
          </a>
          
        </Modal.Footer>
      </Modal>
      </Container>
    </>
    );
  }
export default Contact;