import {typeText} from './type'
//import {s as script} from './slider.js'
//import {driven as XHR} from './xhr.js'
//import {pointer} from './mouse.js'
function Main(){
return(

window.onload = async()=>
         {  
            setTimeout(function(){ typeText(); }, 4200); 
           // await script()
            //await XHR()
            //await pointer()
            //await sticky()
         }

);
      
}
export default Main;


