
import React from 'react';
import {Button} from 'react-bootstrap';
import Reparacion from './img/tecnico.png';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Intro from './Intro'
import Service from './Service'
import Contact from './Contact';

function card()
{
    return(
        <Card className="text-center">
          <Card.Header>
            <Card.Title>ATENDEMOS TODAS LAS MARCAS</Card.Title>
          </Card.Header>
          <Card.Body>
              <Row className="align-items-center">
                <Col md={4}>
                  <img src={Reparacion} className="d-small" alt="servicio"/>
                </Col>
                <Col md={8}>
                  <Card.Text className="fw-bold">
                  CENTRO DE SERVICIO ESPECIALIZADO EN MANTENIMIENTO Y REPARACI&Oacute;N DE LAVADORAS, NEVERAS Y NEVECONES.
                  </Card.Text>
                </Col>
              </Row>
          </Card.Body>
          <Card.Footer className="text-dark fw-bold">
            <Card.Text>
            SERVICIO GARANTIZADO
            </Card.Text>
          </Card.Footer>
        </Card>
        );
}

class Phones extends React.Component
{
  render()
    {
     return(
       <div>
          <h4 className="md"><i className="bi bi-phone lg"></i>Atenci&oacute;n Inmediata</h4>
          <h1 className="xl">604&nbsp;322&nbsp;9262</h1>
          <h1 className="xl">301&nbsp;245&nbsp;2679</h1>
          <Button variant="primary" className="navbar mx-auto"><a href="tel:6043229262">Cont&aacute;ctenos</a></Button>
          <div className="spacer-mid"></div>
          <Card.Title>Contamos con m&aacute;s de 10 a&ntilde;os de experiencia y t&eacute;cnicos especializados.</Card.Title>
      </div>
     ); 
    }
}

export default class Home extends React.Component
{
 render ()
 {
  return(
    <div>
      <Container fluid>
        <Row className="align-items-center">
          <Col md={{order: 'first' }} className="mx-auto">{card()}</Col>
          <Col md={4}><Phones/></Col>
          <Col md={{order: 'last' }} ><Intro /></Col>
        </Row>
      </Container>
      <Service />
      <Contact />        
    </div>  
  ); 
 }
}

